

const card = [
    {
        id:"1",
        imgsrc:"../assets/cards/2.png",
        img:'./assets/paper.png',
        heading:"Paper",
        paragraph:"Variety of different Paper and matt engaged with different size , grame and shade. In general, 60 grame to 170 grame. Size have different variety such as , 20x30, 23x36 ,25x36 , 30x40, etc",
        heading1:"Company deal different variety of Paper such as different grame with different size according to customer requirements. Paper use in different field such as broucher, books, magazine, medicine, pufflet, etc. It can use in any field for advertisement.",
        brandoptions:['Hutai','Golden Sun','Japani','Nevia'],
        sizeoption:['20*30','23*36','25*36','30*40','Other'],
        grameoption:['55','60','65','70','75','80','85','90','100','105','110','113','115','124','128','130','148','150'],
        category : [
          { value: "Artpaper", text: "Art paper" , image: "../assets/products/artcard.jpg" },
          { value: "Mattpaper", text: "Matt paper" , image: "./assets/products/ncr.jpg" },
          
        ],
        packing:['250','500'],
        Link: '/card1',
    },
    {
        id:"2",
        imgsrc:"../assets/cards/6.png",
        img:"./assets/5.png",
        heading:"Card/Board",
        paragraph:"Variety of different Board engaged with different size , grame and shade. In general, 190 grame to 350 grame. Size have different variety such as 20x30 , 23x36 ,25x36 , 30x40, etc",
        heading1:"Company deal different variety of Board such as different grame with different size according to customer requirements. Board use in different field such as  medicine, boxes, gifts, fastfood, packing, calender etc. It can use in any field for advertisement.",
        brandoptions:['Pindo','Ningbo star','Bohee','Ningbo gloss','Graphic'],
        sizeoption:['20*30','22*28','23*36','25*36','30*40','31*43', 'Other'],
        grameoption:['190','200','210','230','240','250','260','270','290','300','320','350'],
        category : [
          { value: "Artcard", text: "Art card" , image: "../assets/products/artcard.jpg" },
        ],
        packing:['100'],
        Link: '/card2',
    },
    {
        id:"3",
        imgsrc:"../assets/cards/1.png",
        img:"./assets/8.png",
        heading:"Offset Paper",
        paragraph:"Variety of different Offset paper engaged with different size , grame and shade. In general, 50 grame to 150 grame. Size have different variety such as  20x30 , 23x36 ,25x35.5 , 30x40, etc",
        pcode:"LCU-356",
        heading1:"Company deal different variety of Offset paper such as different grame with different size according to customer requirements. Offest paper use in different field such as broucher, books, magazine, medicine, pufflet, etc. It can use in any field for advertisement.",
        sku:"4394875983",
        brandoptions:['Ik','Paper plus','Paper one','April','Rc'],
        sizeoption:['20*30','22*28', '23*36','25*35.5','30*40','Other'],
        grameoption:['50','55','60','65','68','70','75','80','85','90','100','120'],
        category : [
            { value: "Offsetpaper", text: "Offset paper" , image: "../assets/products/artcard.jpg" },
          ],
          packing:['100','250','500'],
        Link: '/card3',
    },
    {
        id:"4",
        imgsrc:"../assets/cards/7.png",
        img:"./assets/9.png",
        heading:"Stickers",
        paragraph:"A lot of variety with different adhesive and uses. It has general size 20x30. It use in different process such as box of cosmetics , oil bottles and autoparts , etc",
        heading1:"Company deal with  different variety of Stickers which is use in different products such as cosmetics, bootles, glass, juices, mobiles, all food items, all electronic items, all grocery items, etc. It can use in any field for advertisement.",
        brandoptions:['IMH','Real Royal','123NO','YMC','Maxtac','GSF(MMC)','Prime','Special sticker','Lintec','Jacc','Serilac'],
        sizeoption:['20*30'],
        grameoption:['0'],
        category : [
          { value: "Color", text: "Color" , image: "../assets/products/artcard.jpg" },
          { value: "Krft", text: "Krft" , image: "./assets/products/ncr.jpg" },
          { value: "Matt", text: "Matt" , image: "../assets/products/artcard.jpg" },
          { value: "White", text: "White" , image: "../assets/products/artcard.jpg" },
          { value: "Warranty", text: "Warranty" , image: "../assets/products/artcard.jpg" },
        ],
        packing:['100'],
        Link: '/card4',
    },
    {
        id:"5",
        imgsrc:"../assets/cards/1.png",
        img:"./assets/11.png",
        heading:"Albaster Card",
        paragraph:"Variety of different Albaster Card engaged with different size , grame and shade. (120, 150, 180, 200, 230, 265,etc) grames are available. Size have different variety such as , 21x28 & 22x28",
        heading1:"Company deal with  different variety of Every / Albaster card such as different grame with different size according to customer requirements. Every / Albaster card use in different field such as  medicine, boxes, gifts, fastfood, packing, calender etc. It can use in any field for advertisement.",
        brandoptions:['Ik','Pindo','April','Nevia'],
        sizeoption:['21*28','21.5*27.5','22*28'],
        grameoption:['120','150','180','200','210','220','230','235','265','285','300','335'],
        category : [
          { value: "Every", text: "Every" , image: "../assets/products/artcard.jpg" },
          { value: "Albaster", text: "Albaster" , image: "./assets/products/ncr.jpg" },
        ],
        packing:['100'],
        Link: '/card5',
    },
    {
        id:"6",
        imgsrc:"../assets/cards/9.png",
        img:"./assets/7.png",
        heading:"Melamine",
        paragraph:"Variety of different Melamine engaged with different size and shade. It has general size 23x36.",
        heading1:"Company deal with Melamine paper which is used for grocery printed such plates, mugs, bowls, etc. It can use in any field for advertisement.",
        brandoptions:['Japani','Oji'],
        sizeoption:['23*36'],
        grameoption:['45','50','52C','52J','52R'],
        category : [
          { value: "Melamine", text: "Melamine" , image: "../assets/products/artcard.jpg" }
        ],
        packing:['500'],
        Link: '/card6',
    },
    {
        id:"7",
        imgsrc:"../assets/cards/3.png",
        img:"./assets/3.png",
        heading:"Butter Paper",
        paragraph:"Variety of different Butter paper engaged with different size and shade.It is also available in cutting and use for shawarma, burger and all other fast food.",
        heading1:"Company deals with Butter paper with different grame and size. It can use for fast food bakkery items, sweets such shawarma, burger, pizza, cookies, etc. It can use in any field for advertisement. ",
        brandoptions:['China'],
        sizeoption:['18.5*28','18.5*29','18.5*29.5','29*37'],
        grameoption:['19','21','24','27','35'],
        category : [
          { value: "Butter", text: "Butter" , image: "../assets/products/artcard.jpg" },
        ],
        packing:['400','500'],
        Link: '/card7',
    },
    {
        id:"8",
        imgsrc:"../assets/cards/5.png",
        img:"./assets/4.png",
        heading:"Carbonless",
        paragraph:"Variety of different Carbonless engaged with different colors , size , grame and shade. Under 50 & heavy grame also available. Size have different variety such as  17x24 and 24x34.",
        heading1:"Company deals with Carbonless with different colors and grame. It can use for bill books, school books, art, etc.",
        brandoptions:['Impression','Pindo','ImpressionIMH','Star','Rising sun'],
        sizeoption:['17*24','24*34'], 
        grameoption:['48','49','50','55','70','75'],
        category : [
          { value: "Carbonless", text: "Carbonless" , image: "../assets/products/artcard.jpg" },
        ],
        packing:['500'],
        colors:['white','yellow','green','pink','blue'],
        Link: '/card8',
    },
    {
        id:"9",
        imgsrc:"../assets/cards/6.png",
        img:"./assets/11.png",
        heading:"Cromo Card",
        paragraph:"Variety of different Cromo Card engaged with different size , grame and shade. (150 , 200 and 250) grames are available. It has general size 22x28.", 
        heading1:"Company deal with different variety of Cromo card such as different grame with different size according to customer requirements. Cromo card use in different field such as  medicine, boxes, gifts, fastfood, packing, calender etc. It can use in any field for advertisement.",
        brandoptions:['Pindo'],
        sizeoption:['22*28'],
        grameoption:['150','200','250'],
        category : [
          { value: "IMH", text: "IMH" , image: "../assets/products/artcard.jpg"}
        ],
        packing:['100'],
        Link: '/card9',
    },
    // {
    //     id:"10",
    //     imgsrc:"../assets/cards/11.png",
    //     img:"./assets/2.png",
    //     heading:"A4 Box",
    //     paragraph:"Variety of different Paper and matt engaged with different size , grame and shade. In general, 60 grame to 170 grame. Size have different variety such as , 20x30, etc",
    //     heading1:"Company deal with A4 size paper. It is used for printer.",
    //     brandoptions:['BLC'],
    //     sizeoption:['8.3*11.7'],
    //     grameoption:['07'],
    //     category : [
    //       { value: "A4", text: "A4" , image: "../assets/products/artcard.jpg" },
    //     ],
    //     packing:['500'],
    //     Link: '/card10',
    // },
    {
        id:"10",
        imgsrc:"../assets/cards/8.png",
        img:"./assets/1.png",
        heading:"Bleach Card",
        paragraph:"Variety of different Paper and matt engaged with different size , grame and shade. In general, 60 grame to 170 grame. Size have different variety such as , 20x30, etc",
        heading1:"Company deal with different variety of Bleach card such as different grame with different size according to customer requirements. Bleach card use in different field such as  medicine, boxes, cookies box, pizza box, gifts, fastfood, packing, calender etc. It can use in any field for advertisement.",
        brandoptions:['Ningbo fold'],
        sizeoption:['20*30','22*28','23*36','25*36','25*30'],
        grameoption:['210','230','250','270','300','350'],
        category : [
          { value: "Bleach", text: "Bleach" , image: "../assets/products/artcard.jpg" },
        ],
        packing:['100'],
        Link: '/card11',
    },
    {
      id:"11",
      imgsrc:"../assets/cards/7.png",
      img:"./assets/6.png",
      heading:"Film-Fancy Sticker",
      paragraph:"Variety of different Paper and matt engaged with different size , grame and shade. In general, 60 grame to 170 grame. Size have different variety such as , 20x30, etc",
      heading1:"Company deal with different variety of Fancy stickers which is use in different products such as cosmetics, bootles, glass, juices, mobiles, all food items, all electronic items, all grocery items, etc. It can use in any field for advertisement.",
      brandoptions:['IMH','Real Royal','Real tac'],
      sizeoption:['20*30'],
      grameoption:['0'],
      category : [
        { value: "Transparent", text: "Transparent" , image: "../assets/products/artcard.jpg" },
        { value: "Golden", text: "Golden" , image: "./assets/products/ncr.jpg" },
        { value: "Silver", text: "Silver", image: "../assets/products/offset.jpg" },
        { value: "Silver50", text: "Silver50", image: "../assets/products/offset.jpg" },
        { value: "Silver75", text: "Silver75", image: "../assets/products/offset.jpg" },
        { value: "PVC", text: "PVC" , image: "../assets/products/artcard.jpg" },
        { value: "Daimond", text: "Daimond" , image: "../assets/products/artcard.jpg" },
        { value: "Dullsilver", text: "Dull Silver" , image: "../assets/products/artcard.jpg" },
        { value: "Silvertransparentback", text: "Silver Transparent back" , image: "../assets/products/artcard.jpg" },
        { value: "Dullgolden", text: "Dull Golden", image: "../assets/products/offset.jpg" },
        { value: "Computermatt", text: "Computer Matt", image: "../assets/products/offset.jpg" },
      ],
      packing:['100'],
      Link: '/card12',
  },
]
export default card;